<template>
  <div class="w-full flex flex-col items-center mt-16">
    <!-- Logo -->
    <div class="logo-background">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M8 20H6C4.89543 20 4 19.1046 4 18V16M16 20H18C19.1046 20 20 19.1046 20 18V16M4 8V6C4 4.89543 4.89543 4 6 4H8M16 4H18C19.1046 4 20 4.89543 20 6V8" 
        stroke="#303546" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 13.5V10.5C8 9.67157 8.67157 9 9.5 9H9.58579C9.851 9 10.1054 8.89464 10.2929 8.70711L10.5607 8.43934C10.842 8.15804 11.2235 8 11.6213 8H12.3787C12.7765 8 13.158 8.15804 13.4393 8.43934L13.7071 8.70711C13.8946 8.89464 14.149 9 14.4142 9H14.5C15.3284 9 16 9.67157 16 10.5V13.5C16 14.3284 15.3284 15 14.5 15H9.5C8.67157 15 8 14.3284 8 13.5Z" 
        stroke="#303546" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <!-- Title -->
    <BaseText type="heading" size="md" class="text-text-loud mt-2 text-center">
      Capture creative inspiration wherever you are
    </BaseText>
    <BaseText type="body" size="sm" class="text-text-muted mt-2 text-center" style="max-width: 544px">
      Stop blowing up the team group chat! Foreplay is the <b>only platform</b> that allows
      you to save ad inspiration from Instagram or TikTok.
    </BaseText>
    <!-- Cards -->
    <div class="grid grid-cols-2 gap-x-5 mt-10" style="height: 464px">
      <!-- Instagram DM saving card -->
      <div class="flex flex-col items-center px-7 pb-7 rounded-2xl border border-border-normal transition-colors hover:border-border-hover"
      @mouseenter="handleIgDmCardHovered" @mouseleave="handleIgDmCardUnhovered">
        <!-- Tutorial video -->
        <div class="relative" style="width: 300px; height: 248px">
          <video ref="tutorialVideo" preload="metadata" @loadedmetadata="videoLoaded = true"
          class="w-full h-full transition-opacity" :class="videoLoaded ? 'opacity-100' : 'opacity-0'">
            <source src="https://publicassets.foreplay.co/ig-dm-tutorial.webm" type="video/webm">
          </video>
          <!-- Thumbnail overlay -->
          <transition>
            <img v-if="!videoPlaying" src="https://publicassets.foreplay.co/ig-dm-tutorial-thumbnail.png" 
            class="absolute top-0 bottom-0 left-0 right-0 z-10 object-scale-down" style="width: 300px; height: 248px">
          </transition>
        </div>
        <!-- Title -->
        <BaseText type="label" size="lg" class="text-text-muted mt-4">
          Save using Instagram DMs
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal text-center mt-1" style="max-width: 300px">
          Save ads to your Foreplay library by sending them to @foreplay_co on Instagram.
        </BaseText>
        <!-- (For linked users) Edit IG connection button -->
        <button v-if="getUser.igUsername" class="connected-ig-button relative rounded-md px-2 py-1.5 mt-6"
        style="max-width: 300px" @mouseenter="igButtonHovered = true" @mouseleave="igButtonHovered = false"
        @click="handleConnectInstagram">
          <div class="flex items-center gap-1.5 transition-opacity min-w-0" :class="igButtonHovered ? 'opacity-0' : 'opacity-100'">
            <CheckmarkIcon class="text-icon-normal flex-shrink-0" />
            <BaseText type="label" size="sm" class="text-text-muted flex-shrink-0">
              Connected
            </BaseText>
            <BaseText type="body" size="sm" class="text-text-normal flex-shrink truncate">
              @{{ getUser.igUsername }}
            </BaseText>
          </div>
          <transition name="fade">
            <div v-if="igButtonHovered" class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
              <BaseText type="label" size="sm" class="text-text-muted">
                Edit Connection
              </BaseText>
            </div>
          </transition>
        </button>
        <!-- (For non-linked users) Connect IG button -->
        <button v-else class="ig-button flex items-center gap-2 pl-1.5 py-1.5 pr-2 rounded-md mt-6"
        @click="handleConnectInstagram">
          <img src="../../assets/images/instagram.png" class="w-5 h-5">
          <BaseText type="label" size="sm" class="text-text-muted">
            Connect Your Instagram
          </BaseText>
        </button>
        <!-- Watch video button -->
        <button class="group flex items-center gap-1.5 pl-1.5 py-1.5 pr-2 rounded-md mt-4 transition-colors hover:bg-neutral-25"
        @click="showTutorialModal = true">
          <PlayIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Watch Video
          </BaseText>
        </button>
      </div>
      <!-- Foreplay App saving card -->
      <div class="flex flex-col items-center p-7 rounded-2xl border border-border-normal transition-colors hover:border-border-hover">
        <!-- IOS/Android toggle -->
        <div class="flex items-center gap-0.5 p-0.5 rounded-lg bg-neutral-25">
          <button class="toggle-button group flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md"
          :class="{'active': qrCodeType === 'ios'}" @click="qrCodeType = 'ios'">
            <img src="../../assets/icons/apple-logo.svg">
            <BaseText type="label" size="sm" class="text-text-muted"
            :class="{'transition-colors group-hover:text-neutral-700': qrCodeType !== 'ios'}">
              AppStore
            </BaseText>
          </button>
          <button class="toggle-button group flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md"
          :class="{'active': qrCodeType === 'android'}" @click="qrCodeType = 'android'">
            <img src="../../assets/icons/google-play-logo.svg">
            <BaseText type="label" size="sm" class="text-text-muted"
            :class="{'transition-colors group-hover:text-neutral-700': qrCodeType !== 'android'}">
              GooglePlay
            </BaseText>
          </button>
        </div>
        <!-- QR Code display -->
        <div style="width: 136px; height: 136px; margin-top: 44px">
          <img v-show="qrCodeType === 'ios'" src="../../assets/images/ios-app-store-qr-code.png" class="w-full h-full">
          <img v-show="qrCodeType === 'android'" src="../../assets/images/android-app-store-qr-code.png" class="w-full h-full">
        </div>
        <!-- Title -->
        <BaseText type="label" size="lg" class="text-text-muted text-center mt-8" style="max-width: 244px">
          Save from TikTok Mobile with the Foreplay App
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal text-center mt-1" style="max-width: 244px">
          Save ads from TikTok by sharing them with the Foreplay mobile app.
        </BaseText>
        <!-- Watch video button -->
        <!-- <button class="group flex items-center gap-1.5 pl-1.5 py-1.5 pr-2 rounded-md mt-8 transition-colors hover:bg-neutral-25">
          <PlayIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Watch Video
          </BaseText>
        </button> -->
      </div>
    </div>
    
    <!-- TutorialModal -->
    <BaseTutorialModal 
      v-if="showTutorialModal"
      videoUrl="https://www.youtube.com/embed/GmX6IOyNYtY?si=uPE7aRrO714DJ5Qs"
      :timestamp="47"
      @close="showTutorialModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlayIcon from '../globals/Icons/PlayIcon.vue'
import CheckmarkIcon from '../globals/Icons/NewCheckmarkIcon.vue';

export default {
  name: 'LibraryMobileSaving',
  components: {
    PlayIcon,
    CheckmarkIcon
  },
  data () {
    return {
      qrCodeType: 'ios',
      videoLoaded: false,
      videoPlaying: false,
      videoResetTimeout: null,
      igCardHovered: false,
      igButtonHovered: false,
      showTutorialModal: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser'])
  },
  mounted () {
    // Play the video once without looping
    this.$nextTick(() => {
      const video = this.$refs.tutorialVideo
      if (!video) return
      video.play()
      this.videoPlaying = true
      video.addEventListener('ended', () => {
        video.loop = true
        if (!this.igCardHovered) {
          video.pause()
          video.currentTime = 0
          this.videoPlaying = false
        } else {
          video.play()
          this.videoPlaying = true
        }
      }, { once: true })
    })
  },
  methods: {
    handleConnectInstagram () {
      this.$router.push({ name: 'DashboardView', hash: '#instagram' })
    },
    handleIgDmCardHovered () {
      this.igCardHovered = true
      const video = this.$refs.tutorialVideo
      if (video) {
        clearTimeout(this.videoResetTimeout)
        video.play()
        this.videoPlaying = true
      }
    },
    handleIgDmCardUnhovered () {
      this.igCardHovered = false
      const video = this.$refs.tutorialVideo
      if (video) {
        video.pause()
        this.videoPlaying = false
        this.videoResetTimeout = setTimeout(() => {
          video.currentTime = 0
        }, 100)
      }
    }
  }
}
</script>

<style scoped>
.logo-background {
  padding: 18px;
  border-radius: 10px;
  background: linear-gradient(180deg, #F6F8FA 0%, transparent 100%);
}

@property --ig-btn-bg-opacity {
  syntax: "<number>";
  initial-value: 0.08;
  inherits: false;
}
.ig-button {
  background: linear-gradient(180deg, rgba(223, 225, 231, var(--ig-btn-bg-opacity)) 0%, transparent 100%);
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out, --ig-btn-bg-opacity 150ms ease-in-out;
  --ig-btn-bg-opacity: 0.08;
}
.ig-button:hover {
  --ig-btn-bg-opacity: 0;
  background-color: #F6F8FA; /* bg-neutral-25 */
  box-shadow: none;
}

.connected-ig-button {
  background-color: #F6F8FA; /* bg-neutral-25 */
  box-shadow: none;
  transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.connected-ig-button:hover {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}

.toggle-button {
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
  background-color: transparent;
  box-shadow: none;
}
.toggle-button.active {
  box-shadow: 0px 3px 6px 0px rgba(4, 26, 75, 0.04), 0px 1.5px 3px 0px rgba(4, 26, 75, 0.08);
  background-color: white;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.fade-enter-active {
  transition: opacity 150ms ease-in-out;
}
.fade-leave-active {
  transition: opacity 75ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to, .fade-enter-from, .fade-enter, .fade-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from, .fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>